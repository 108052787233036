import React, { Component } from 'react';
import classNames from 'classnames';
import { ReactComponent as Angle } from 'icons/angle-right-light.svg';

import Vacancy from 'components/Careers/Vacancy/Vacancy';

import './Filter.sass';

export default class Filter extends Component {
  constructor() {
    super();

    this.state = {
      active: 0,
      selectOpened: false
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleAnswerClick = this.handleAnswerClick.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      active: this.props.active
    });
    setTimeout(() => {
      window.scrollTo(0, this.props.topOffset)
    }, this.props.timeout)
  }

  handleAnswerClick(e, index) {
    e.preventDefault();

    const { active } = this.state;
    this.props.handleClick(active, index);
  }

  handleTabClick(e, index) {
    this.setState({
      active: index,
      selectOpened: false
    });
    this.props.handleActive(index);
  }

  handleSelectClick() {
    this.setState(prevState => ({
      selectOpened: !prevState.selectOpened
    }));
  }


  render() {
    const { title, items } = this.props;
    const { selectOpened, active } = this.state;
    const currentCity = items[active].city;

    return (
      <div className="filter">
        <h2 className="filter__title">{title}</h2>

        <div className="filter__content">
          <div className="filter__sidebar">
            <span
              className={classNames("filter__selected", selectOpened ? "is-opened" : "")}
              onClick={this.handleSelectClick}
            >
              {currentCity}
              <Angle/>
            </span>
            <ul className={classNames("filter__cities", selectOpened ? "is-opened" : "")}>
              {
                items.map((item, index) => (
                  <li
                    key={item.city}
                    className={classNames('filter__item', active === index ? 'is-active' : '')}
                    onClick={(e) => this.handleTabClick(e, index)}
                  >
                    {item.city}
                  </li>
                ))
              }
            </ul>
          </div>

          <div className="filter__body">
            {
              items[active].positions.map(({
                title
              }, index) => (
                <div className="filter__vacancy" key={index}>
                  <Vacancy
                    title={title}
                    handleClick={e => this.handleAnswerClick(e, index)}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
};
