import React, { Component } from 'react';
import classNames from 'classnames';

import Answer from 'components/FAQ/Answer/Answer';

import './Tabs.sass';

export default class Tabs extends Component {
  constructor() {
    super();

    this.state = {
      active: 0
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleAnswerClick = this.handleAnswerClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      active: this.props.active
    });
    setTimeout(() => {
      window.scrollTo(0, this.props.topOffset)
    }, this.props.timeout)
  }

  handleAnswerClick(e, index) {
    e.preventDefault();

    const { active } = this.state;
    this.props.handleClick(active, index);
  }

  tabScrollToActive(e) {
    const scrollPosition = e.currentTarget.offsetLeft - 20;

    this.tabsContainer.scrollTo({
      top: 0,
      left: scrollPosition,
      behavior: 'smooth'
    });
  }

  handleTabClick(e, index) {
    this.tabScrollToActive(e);

    this.setState({
      active: index
    });
    this.props.handleActive(index);
  }

  renderLine() {
    const style = {};

    if (this.tabsContainer) {
      const activeTab = this.tabsContainer.childNodes[this.state.active];
      style.width = activeTab.offsetWidth;
      style.left = activeTab.offsetLeft;
    }
    return(
      <div
        className="tabs__active-line"
        style={style}>
      </div>
    )
  }

  render() {
    const { items, common } = this.props;

    return (
      <div className="tabs">
        <div className="tabs__header">
          <ul className="tabs__list" ref={node => this.tabsContainer = node}>
            {
              items.map((item, index) => (
                <li
                  key={item.category}
                  className={classNames('tabs__item', this.state.active === index ? 'is-active' : '')}
                  onClick={(e) => this.handleTabClick(e, index)}
                >
                  {item.category}
                </li>
              ))
            }
          </ul>
          {this.renderLine()}
        </div>
        <div className="tabs__body">
          {
            items[this.state.active].questions.map(({
              title,
              answer,
              content
            }, index) => (
              <div className="tabs__answer" key={title}>
                <Answer
                  title={title}
                  text={answer}
                  common={common}
                  hasFullAnswer={content ? true : false}
                  handleClick={e => this.handleAnswerClick(e, index)}
                />
              </div>
            ))
          }
        </div>
      </div>
    );
  }
};
