import React, { Component } from 'react';

import { TimelineMax, TweenLite } from 'gsap/all';
import ScrollMagic from 'scrollmagic';

import _throttle from 'lodash.throttle';

import { getPageDataUrl } from 'Utils';

import Hero from 'sections/Hero/Hero';
import TextBlock from 'sections/TextBlock/TextBlock';
import Demo from 'sections/Demo/Demo';
import Video from 'sections/Video/Video';
import Benefits from 'sections/Benefits/Benefits';
import Roadmap from 'sections/Roadmap/Roadmap';
import Reviews from 'sections/Reviews/Reviews';
import JoinUs from 'sections/JoinUs/JoinUs';

const pageDataUrl = getPageDataUrl('homepage');
const elementsDataUrl = getPageDataUrl('elements');

export default class Home extends Component {
  constructor() {
    super();

    this.state = {
      pageData: null,
      elementsData: null,
      showOverlay: true
    };

    this.classes = {
      roadmap: {
        container: '.js-roadmap',
        headline: '.js-roadmap-headline'
      },
      video: {
        curtain: '.js-video-curtain',
        content: '.js-video-content',
        shadow: '.js-video-shadow',
        playBtn: '.js-video-button',
      },
      benefits: {
        items: '.js-benefits-item'
      }
    };

    this.directions = {
      forward: 'FORWARD',
      reverse: 'REVERSE'
    };

    this.controller = new ScrollMagic.Controller();

    this.getVideoTl = this.getVideoTl.bind(this);
    this.getBenefitsTl = this.getBenefitsTl.bind(this);
    this.handleResize = this.handleResize.bind(this)
  }

  update() {
    this.scenes && this.scenes.forEach(item => item
      .refresh(true)
      .update(true)
    );
    this.controller.update(true);
  }

  handleResize() {
    this.isTablet = window.innerWidth <= 1024;
    this.update();
  }

  destroy() {
    this.controller.destroy(true);
    this.scenes && this.scenes.forEach(item => item.destroy(true));
  }

  componentWillUnmount() {
    this.destroy();
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleResize);
  }

  componentDidMount() {
    this.setData('pageData', pageDataUrl, this.dataLoaded);
    this.setData('elementsData', elementsDataUrl);
    this.isTablet = window.innerWidth <= 1024;

    window.addEventListener('resize', _throttle(this.handleResize, 200));
    window.addEventListener('orientationchange', _throttle(this.handleResize, 200));
  }

  dataLoaded() {
    setTimeout(() => {
      this.createSceneAnimations();
      this.scrollToHash();
      this.setState({
        showOverlay: false
      })
    }, 100);
  }

  scrollToHash() {
    if(!window.location.hash) return;

    const id = window.location.hash;
    const section = id && document.querySelector(id);

    if (!section) return;

    const { offsetTop } = section;
    setTimeout(() => {
      window.scrollTo(0, offsetTop);
    }, 200)
  }

  getBenefitsTl(container) {
    const {
      items
    } = this.classes.benefits;

    const video = container.querySelector('video');

    return new TimelineMax({
      paused: true
    })
      .staggerFromTo(items, 0.7, { autoAlpha: 0, y: '30%' }, { autoAlpha: 1, y: '0%', className: '+=is-animated' }, 1.2)
      .staggerTo(items, 0.7, { autoAlpha: 0, y: '-30%', className: '-=is-animated' }, 1.2, 0.7)
      .fromTo(
        '.js-benefits-video',
        0.2,
        {
          autoAlpha: 0
        },
        {
          autoAlpha: 1,
          onComplete: () => {
            if (video.getAttribute('src')) return;

            const { src } = video.dataset;
            video.setAttribute('src', src);
          }
        },
        '-=1.2'
      )
      .to('.js-benefits-video', 1, { autoAlpha: 0 }, '-=0.2')
    ;
  }

  getVideoTl() {
    const {
      curtain,
      content,
      shadow,
      playBtn
    } = this.classes.video;

    const curtains = [...document.querySelectorAll(curtain)];

    return new TimelineMax({
      paused: true
    })
      .to(curtains[0], 1, { y: '-100%' })
      .to(curtains[1], 1, { y: '100%' }, 0)
      .to(curtains[2], 1, { x: '-100%' }, 0)
      .to(curtains[3], 1, { x: '100%' }, 0)
      .fromTo(playBtn, 1, { autoAlpha: 1 }, { autoAlpha: 0 }, '-=0.2')
      .fromTo(content, 2, { y: window.innerHeight }, { y: 0 }, '-=0.5')
      .fromTo(shadow, 0.5, { autoAlpha: 0 }, { autoAlpha: 1 }, '-=0.5')
    ;
  }

  roadmapOnEnter() {
    const {
      headline: headlineClassName
    } = this.classes.roadmap;

    const headline = document.querySelector(headlineClassName);

    const {
      top,
      left
    } = headline.getBoundingClientRect();

    TweenLite.set(headlineClassName, { position: 'fixed', top, left, y: 0 });
  }

  roadmapOnLeave({ scrollDirection }) {
    const {
      headline: headlineClassName,
      container: containerClassName
    } = this.classes.roadmap;

    const container = document.querySelector(containerClassName);
    const headline = document.querySelector(headlineClassName);

    if (scrollDirection === this.directions.forward) {
      const y = container.children[0].offsetHeight - headline.offsetHeight;
      TweenLite.set(headline, { position: 'relative', top: 0, left: 0, y });
    } else {
      TweenLite.set(headline, { position: 'relative', top: 0, left: 0 });
    }
  }

  getAnimation(name, container) {
    return ({
      roadmap: {
        durationCoef: 0.87,
        onEnter: this.roadmapOnEnter,
        onLeave: this.roadmapOnLeave,
        trigerHook: 0.01
      },
      video: {
        durationCoef: 2,
        pin: true,
        trigerHook: 0.001,
        tl: this.getVideoTl,
      },
      benefits: {
        durationCoef: 12,
        pin: true,
        trigerHook: 0.001,
        tl: this.getBenefitsTl.bind(this, container)
      }
    }[name]);
  }

  createSceneAnimations() {
    const containers = [...document.querySelectorAll('.js-scene')];

    this.scenes = containers.map((el) => {
      const { sceneName } = el.dataset;
      const animation = this.getAnimation(sceneName, el);

      const {
        durationCoef,
        trigerHook,
        pin,
        onEnter,
        onLeave
      } = animation;

      const tl = animation.tl;

      const { offsetHeight } = el;
      const duration = durationCoef ? offsetHeight * durationCoef : offsetHeight;

      const scene =  new ScrollMagic.Scene({
        duration,
        triggerElement: el,
        triggerHook: trigerHook || 0.5
      });

      if (tl) scene.setTween(tl().play());
      if (pin) scene.setPin(el, { pushFollowers: true });

      if (!this.isTablet) {
        if (onEnter) scene.on('enter', onEnter.bind(this));
        if (onLeave) scene.on('leave', onLeave.bind(this));
      };

      scene.addTo(this.controller);
      return scene;
    });
  }

  async setData(stateKey, url, cb) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Something goes wrong');
      }
      const myJson = await response.json();
      this.setState({
        [stateKey]: myJson[0].acf
      }, cb);
    } catch (error) {
      console.log(error.message);
    }
  }

  render() {
      return (
        this.state.pageData && this.state.elementsData
          ? <div className="home">
            {this.state.showOverlay ? <div className="main-overlay"></div> : null}
              <Hero
                title={this.state.pageData.hero.title}
                subtitle={this.state.pageData.hero.subtitle}
                text={this.state.pageData.hero.text}
                btn={this.state.pageData.hero.btn}
              />
              <TextBlock text={this.state.pageData.about} />
              <Demo
                items={this.state.pageData.demo.items}
                footer={this.state.pageData.demo.footer}
                interface={this.state.pageData.demo.interface}
              />
              <div id="advantages">
                <Video
                  headline={this.state.pageData.video.headline}
                  text={this.state.pageData.video.text}
                  src="/video/1025767460-preview.mp4"
                />
              </div>
              <Benefits items={this.state.pageData.benefits.map(item => item.benefit)} />
              <Roadmap
                headline={this.state.pageData.roadmap.headline}
                items={this.state.pageData.roadmap.items.map(item => item.item)}
                active={this.state.pageData.roadmap.active}
              />
              <Reviews slides={this.state.pageData.reviews.slides.map(item => item.slide)} />
              <JoinUs
                headline={this.state.elementsData.join.headline}
                subscribe={this.state.elementsData.join.subscribe}
                partner={this.state.elementsData.join.partner}
                comunity={this.state.elementsData.join.comunity}
              />
            </div>
          : ''
      )
  }
};
