import React, { Component } from 'react';

import './Footer.sass';

import Logo from 'components/Logo/Logo';
import Nav from 'components/Nav/Nav';

export default class Footer extends Component {
  render() {
    return (
            <footer className="footer">
              <div className="footer__in">
                <Logo className="footer__logo" src="/img/logo-dark.svg" alt="Logo" />
                <Nav className="footer__nav" links={this.props.nav} isDark={true} />
                <div className="footer__links">
                  {this.props.links.map(item =>
                    <a
                      href={item.url}
                      key={item.title}
                      className="footer__link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  )}
                </div>
              </div>
              <div className="footer__text">
                <p>{this.props.mob.footer.text}</p>
              </div>
            </footer>
    );
  }
};
