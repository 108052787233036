import React, { Component } from 'react';
import classNames from 'classnames';

import './Tooltip.sass';

export default class Tooltip extends Component {
  render() {
    return (
      <div
        className={classNames('tooltip', this.props.className)}
      >
        <div className="tooltip__in">
          {this.props.value}
        </div>
      </div>
    );
  }
};
