import React, { Component } from 'react';

import Slider from 'components/Slider/Slider';

import './Reviews.sass';

export default class Slides extends Component {
  render() {
    return (
      <section className="reviews">
        <Slider slides={this.props.slides} />
      </section>
    );
  }
};
