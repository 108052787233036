import React, { Component } from 'react';

import { getPageDataUrl } from 'Utils';

import { default as CareersSection } from 'sections/Careers/Careers';
import JoinUs from 'sections/JoinUs/JoinUs';

import './Careers.sass';

const pageDataUrl = getPageDataUrl('careers');
const elementsDataUrl = getPageDataUrl('elements');

export default class Careers extends Component {
  constructor() {
    super();

    this.state = {
      pageData: null,
      elementsData: null,
    };
  }

  componentDidMount() {
    this.setData('pageData', pageDataUrl);
    this.setData('elementsData', elementsDataUrl);
  }

  async setData(stateKey, url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Something goes wrong');
      }
      const myJson = await response.json();
      this.setState({
        [stateKey]: myJson[0].acf
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  render() {
    return (
      this.state.pageData && this.state.elementsData
      ? <div className="careers">

          <CareersSection
            title={this.state.pageData.title}
            items={this.state.pageData.vacancies}
            btn={this.state.pageData.btn}
          />

          <JoinUs
            allScreensShown={true}
            headline={this.state.elementsData.join.headline}
            subscribe={this.state.elementsData.join.subscribe}
            partner={this.state.elementsData.join.partner}
            comunity={this.state.elementsData.join.comunity}
          />
        </div>
      : ''
    )
  }
};
