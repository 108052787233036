import React, { Component } from 'react';

import './Circles.sass';

export default class Circles extends Component {
  getCircles() {
    const circles = [];

    for (let i = 0; i < this.props.count; i++) {
      circles.push(<span key={i}></span>);
    }

    return circles;
  }
  render() {
    return (
      <div className="circles">{this.getCircles()}</div>
    );
  }
};
