import React, { Component } from 'react';

import AboutItem from 'components/About/AboutItem/AboutItem';
import AboutStory from 'components/About/AboutStory/AboutStory';
import AboutCoin from 'components/About/AboutCoin/AboutCoin';
import Partners from 'components/Partners/Partners';

import './About.sass';
import AboutNews from "../../components/About/AboutNews/AboutNews";

export default class About extends Component {
	render() {
		const {
			text,
			image,
			items,
			story,
			coin,
			partners,
			news,
			team
		} = this.props;

		return (
			<div className="about-section">
				<div className="about-section__in">
					<div className="about-section__headline" data-aos="fade-up">
						{text}
					</div>
					<div className="about-section__image" data-aos="fade-up">
						<img src={image.url} alt={image.alt}/>
					</div>
					<div className="about-section__items">
						{items.map(item => (
							<AboutItem
								key={item.title}
								color={item.color}
								title={item.title}
								text={item.text}
							/>
							))}
					</div>
				</div>
				<AboutStory
					title={story.title}
					textFirstCol={story.textFirstCol}
					textSecondCol={story.textSecondCol}
					image={story.image}
					btn={story.btn}
					moreBtn={story.more}
					team={story.team}
				/>
				<AboutCoin
					title={coin.title}
					subtitle={coin.subtitle}
					text={coin.text}
					items={coin.items}
				/>
				<Partners
					title={partners.title}
					items={partners.items}
				/>
				<AboutNews
					title={news.title}
					slides={news.slides}
					moreBtn={news.moreBtn}
				/>
			</div>
		)
	}
}
