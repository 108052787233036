import React, { Component } from 'react';

import Button from 'components/Button/Button';
import Socials from 'components/Socials/Socials';
import Subscribe from 'components/Subscribe/Subscribe';

import './JoinUs.sass';

export default class JoinUs extends Component {
  render() {
    const { subscribe, partner, comunity } = this.props;

    return (
      <section className="join-us">
        <h3 className="join-us__headline">{this.props.headline}</h3>
        <div className="join-us__row">
          <div className="join-us__col">
            <h5 className="join-us__title">{subscribe.title}</h5>
          </div>
          <div className="join-us__col">
            <h5 className="join-us__title">{partner.title}</h5>
          </div>
          <div className="join-us__col">
            <h5 className="join-us__title">{comunity.title}</h5>
          </div>
        </div>
        <div className="join-us__row">
          <div className="join-us__col">
            <Subscribe {...subscribe.form} />
          </div>
          <div className="join-us__col">
            <Button
              color="violet"
              title={partner.btn.title}
              url={`mailto:${partner.btn.url}`}
              width="full"
              size="sm"
            />
          </div>
          <div className="join-us__col">
            <div className="join-us__socials">
              <Socials
                links={comunity.socials.map(item => item.item)}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
};
