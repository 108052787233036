import React, { Component } from 'react';

import Slider from 'components/Slider/Slider';

import './AboutNews.sass';

export default class AboutNews extends Component {
	render() {
		const {
			title,
			slides,
			moreBtn
		} = this.props;

		return (
			<div className="news-about">
				<h3 className="news-about__headline" data-aos="fade-up">{title}</h3>
				<Slider slides={slides} moreBtn={moreBtn} newsSlider/>
			</div>
		);
	}
};
