import React, { Component } from 'react';

import 'particles.js';

import { ReactComponent as Coin } from 'svg/coin.svg';

import Button from 'components/Button/Button';

import './Hero.sass';

export default class Hero extends Component {
  constructor() {
    super();

    this.coin = {
      circles: '#outer-circles',
      logo: '#circle-logo'
    };
  }

  initParticles() {
    window.particlesJS.load(
      'js-particle',
      '/data/particles.json'
    );
  }

  componentDidMount() {
    this.initParticles();
  }

  render() {
    const {
      title,
      subtitle,
      text,
      btn
    } = this.props;

    return (
      <section className="hero">
        <div id="js-particle" className="hero__particle"></div>
        <div className="hero__block">
          <h1 className="hero__title">{title}</h1>
          <h3 className="hero__subtitle">{subtitle}</h3>
          <p className="hero__text">{text}</p>
          <div className="hero__btn">
            <Button
              color="pink"
              title={btn.title}
              url={btn.url}
            />
          </div>
        </div>
        <div className="hero__coin">
          <Coin />
        </div>
      </section>
    );
  }
};
