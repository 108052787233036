import React, { Component } from 'react';

import _throttle from 'lodash.throttle';

import './Video.sass';

export default class Video extends Component {
  constructor() {
    super();

    this.classes = {
      lazy: {
        video: '.js-lazy-video'
      }
    };
  }

  lazy() {
    this.isTablet = window.innerWidth <= 1024;
    if (this.isTablet) return;

    const { video } = this.classes.lazy;
    [...document.querySelectorAll(video)].forEach(item => {
      item.setAttribute('autoPlay', true);
      item.style.zIndex = 1;
    });
  }

  playVideo() {
    this.videoRef.play();
    this.videoRef.style.zIndex = 1;
  }

  handleResize() {
    this.lazy();
  }

  componentDidMount() {
    this.lazy();
    window.addEventListener('resize', _throttle(this.handleResize.bind(this), 200));
    this.videoRef = document.getElementById('mainVideo');
  }

  generateCurtains() {
    const curtains = [];

    for (let i = 0; i < 4; i++) {
      curtains.push(<span className="video__curtain js-video-curtain" key={i}></span>);
    }

    return curtains;
  }

  render() {
    return (
      <section className="video js-scene" data-scene-name="video">
        <div className="video__in">
          <div className="video__media">
            <div className="video__play-btn js-video-button" onClick={this.playVideo.bind(this)}></div>

            <div dangerouslySetInnerHTML={{ __html: `
            <video id="mainVideo" src="${this.props.src}" class="video__item js-lazy-video" preload="metadata" autoplay muted loop playsInline></video>
          ` }}
            />
            <div className="video__shadow js-video-shadow"></div>
            <div className="video__curtains">
              {
                this.generateCurtains()
                .map(item => item)
              }
            </div>
          </div>
          <div className="video__content js-video-content">
            <h2 className="video__headline">{this.props.headline}</h2>
            <div className="video__text">
              <p>{this.props.text}</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
};
