import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Filter from 'components/Careers/Filter/Filter';
import Details from 'components/Careers/Details/Details';

export default class Careers extends Component {
  constructor() {
    super();

    this.state = {
      topOffset: 0,
      activeIndex: 0,
      city: 0,
      vacancy: 0,
      isVacancyShowed: false
    };

    this.animationTime = 450;
    this.vacancyClicked = this.vacancyClicked.bind(this);
    this.setDescription = this.setDescription.bind(this);
    this.getCategoryVacancies = this.getCategoryVacancies.bind(this);
    this.getAllVacancies = this.getAllVacancies.bind(this);
    this.getDefaultActiveItem = this.getDefaultActiveItem.bind(this);
    this.changeActive = this.changeActive.bind(this);
  }

  getCategoryVacancies(obj) {
    return obj.positions.map((item, index) => Object.assign(item, { index }));
  }

  getAllVacancies(items) {
    return items
      .reduce((result, item) => {
        item.positions
        ? result.push(item)
        : result.push(
          items
            .filter(item => item.positions ? item : false)
            .map(item => item.positions)
            .reduce((res, position) => {
              res.positions = res.positions ? res.positions : [];
              position.forEach(current => res.positions.push(current));
              return res;
            }, item)
        )
        ;
        return result;
      }, []);
  }

  setDescription(index) {
    if (index !== null) {
      this.setState({
        vacancy: index,
        isVacancyShowed: true
      });
    } else {
      this.setState({
        isVacancyShowed: false
      });
    }
  }

  vacancyClicked(active, index) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, this.animationTime);

    this.setState({
      city: active,
      vacancy: index,
      isVacancyShowed: true,
      topOffset: window.pageYOffset
    });
  }

  getDefaultActiveItem(items) {
    return items.findIndex(item => item.active === true);
  }

  changeActive(index) {
    this.setState({
      activeIndex: index
    });
  }

  render() {
    const {
      title,
      items,
      btn
    } = this.props;

    const {
      city,
      vacancy,
      isVacancyShowed
    } = this.state;


    const cityObj = items[city];
    const activeItem = cityObj
       ? cityObj.positions[vacancy]
       : null
    ;

    return (
      <section className="vacancies">
        <div className="vacancies__in">
          <TransitionGroup component={null}>
            {
              !isVacancyShowed
              ?
              <CSSTransition
                timeout={this.animationTime}
              >
                <Filter
                  title={title}
                  items={this.getAllVacancies(items)}
                  active={this.state.activeIndex ? this.state.activeIndex : this.getDefaultActiveItem(items)}
                  handleClick={this.vacancyClicked}
                  handleActive={this.changeActive}
                  topOffset={this.state.topOffset}
                  timeout={this.animationTime}
                />
              </CSSTransition>
              : null
            }
          </TransitionGroup>

          <TransitionGroup component={null}>
            {
              isVacancyShowed
              ?
              (
                <CSSTransition
                  timeout={this.animationTime}
                >
                  <Details
                    title={activeItem.title}
                    items={this.getCategoryVacancies(cityObj)}
                    handleClick={this.setDescription}
                    activeItem={activeItem}
                    sidebarTitle={cityObj.city}
                    btn={btn}
                  />
                </CSSTransition>
              )
              : null
            }
          </TransitionGroup>
        </div>

      </section>
    )
  }
};
