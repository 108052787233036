import React, { Component } from 'react';
import classNames from 'classnames';

import './Burger.sass';

export default class Burger extends Component {
  render() {
    return (
      <button
        className={classNames(
          'burger',
          {
            'is-active': this.props.active
          }
        )}
        title="burger"
      >
        <div className="burger__in"></div>
      </button>
    );
  }
};
