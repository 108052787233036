import React, { Component } from 'react';
import classNames from 'classnames';

import { ReactComponent as Arrow } from 'icons/angle-right-light.svg';
import Button from 'components/Button/Button';

import './AboutStory.sass';

export default class AboutStory extends Component {
	constructor() {
		super();
		this.state = {
			more: false
		}

	}
	handleMore() {
		this.setState({
			more: true
		})
	}
	render() {
		const {
			image,
			title,
			textFirstCol,
			textSecondCol,
			btn,
			moreBtn,
			team
		} = this.props;

		const innerHtmlFirst = { __html: textFirstCol };
		const innerHtmlSecond = { __html: textSecondCol };

		return (
			<div className="about-story">
				<div className="about-story__image" data-aos="fade-up">
					<img src={image.url} alt={image.alt}/>
				</div>
				<div className="about-story__content" data-aos="fade-up">
					<div className={classNames('about-story__text', {'is-full': this.state.more})}>
						<div className="about-story__text-left">
							<div className="about-story__title">
								{title}
							</div>
							<div dangerouslySetInnerHTML={innerHtmlFirst}></div>
						</div>
						<div className="about-story__text-right">
							<div className="about-story__text-image">
								<img src={image.url} alt={image.alt}/>
							</div>
							<div dangerouslySetInnerHTML={innerHtmlSecond}></div>
						</div>
					</div>
					{!this.state.more ?
						<button className="about-story__more" onClick={this.handleMore.bind(this)} data-aos="fade-up">
							{moreBtn}
							<Arrow/>
						</button>
						: null
					}
				</div>
				<div className="about-story__team about-team" data-aos="fade-up">
					<div className="about-story__title">Our team</div>
					<div className="about-team__text">
						<p>This way the development of the company started in collaboration with CTO, BizDev, and COO. After the long and thorough search, Vlad also found the proper partner for his ambitious mission.</p>
						<p>The team of Software development company j:Mind.Systems, one of the most advanced technology companies that worked on such prominent cases as WePlay! Esports, ENESTECH Software and Ukrainian Blockchain Association joined forces with EEX. The rest of the story is to be told. Join our community to create the future together.</p>
						
					</div>
					<div className="about-team__image" data-aos="fade-up">
						<img src="/img/our-team.jpg" alt=""/>
					</div>
					
					<div className="about-team__list" data-aos="fade-up">
						<div className="teammate">
							<div className="teammate__picture">
								<img src="/img/vitalii.jpg" alt=""/>
							</div>
							<div className="teammate__name">Vitalii Shevchenko</div>
							<div className="teammate__position">EEX CTO</div>
							<div className="teammate__descr">Strategic thinker and effective communicator. Always making sure that technological needs align with the vision of EEX. A cryptoinvestor and enthusiast. </div>
						</div>
						<div className="teammate">
							<div className="teammate__picture">
								<img src="/img/ihor-snihur.jpg" alt=""/>
							</div>
							<div className="teammate__name">Ihor Snihur</div>
							<div className="teammate__position">EEX BizDev</div>
							<div className="teammate__descr">One who lights up the community spirit in EEX. Brings value of strong, respect-based relationships, where every opportunity is pursued in synergy.</div>
						</div>
						<div className="teammate">
							<div className="teammate__picture">
								<img src="/img/ihor-podosyonov.jpg"  alt=""/>
							</div>
							<div className="teammate__name">Ihor Podosyonov</div>
							<div className="teammate__position">EEX COO</div>
							<div className="teammate__descr">Knows how to keep things in balance. He is equally good in visionary and day-to-day business  administration. With Ihor EEX’s team is always on the top level. </div>
						</div>
					</div>
				</div>
				<div className="about-story__btn" data-aos="fade-up">
					<Button
						centered
						color="pink"
						title={btn.title}
						url={btn.url}
					/>
				</div>
			</div>
		)
	}
}
