import React, { Component } from 'react';

import AboutCard from 'components/About/AboutCard/AboutCard';

import './AboutCoin.sass';

export default class AboutCoin extends Component {
	render() {
		const {
			subtitle,
			title,
			text,
			items
		} = this.props;

		return (
			<div className="about-coin">
				<div className="about-coin__content">
					<div className="about-coin__title" data-aos="fade-up">
						{title}
					</div>
					<div className="about-coin__subtitle" data-aos="fade-up">
						{subtitle}
					</div>
					<div className="about-coin__text" data-aos="fade-up">
						{text}
					</div>
				</div>
				<div className="about-coin__items">
					{items.map(item => (
						<div className="about-coin__item" key={item.title} data-aos="fade-up">
							<AboutCard
								suptitle={item.suptitle}
								title={item.title}
								subtitle={item.subtitle}
								smallTitle={item.smallTitle}
							/>
						</div>
					))}
				</div>
			</div>
		)
	}
}
