import React, { Component } from 'react';
import { ReactComponent as Angle } from 'icons/angle-double-right-solid.svg';

import './Vacancy.sass';

export default class Vacancy extends Component {

  render() {
    const {
      title,
      handleClick
    } = this.props;

    return (
      <div className="vacancy" onClick={handleClick}>
        <div className="vacancy__in">
          <div className="vacancy__title">{title}</div>
        </div>

        <div className="vacancy__icon">
          <Angle/>
        </div>
      </div>
    )
  }
};
