import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './FAQ.sass';

import Tabs from 'components/FAQ/Tabs/Tabs';
import Details from 'components/FAQ/Details/Details';

export default class FAQ extends Component {
  constructor() {
    super();

    this.state = {
      activeIndex: 0,
      topOffset: 0,
      category: 0,
      question: 0,
      isQuestionShowed: false
    };

    this.animationTime = 450;

    this.answerClicked = this.answerClicked.bind(this);
    this.setQuestion = this.setQuestion.bind(this);
    this.getClosestQuestions = this.getClosestQuestions.bind(this);
    this.changeActive = this.changeActive.bind(this);
  }

  getClosestQuestions(obj) {
    const {
      question
    } = this.state;

    const items = obj.questions.map((item, index) => Object.assign(item, { index }));

    const arr = items.slice(question - 2, question);
    const arr1 = items.slice(question, question + 3);

    return [...arr, ...arr1];
  }

  setQuestion(index) {
    if (index !== null) {
      this.setState({
        question: index,
        isQuestionShowed: true
      });
    } else {
      this.setState({
        isQuestionShowed: false
      });
    }
  }

  answerClicked(active, index) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, this.animationTime);

    this.setState({
      category: active,
      question: index,
      isQuestionShowed: true,
      topOffset: window.pageYOffset
    });
  }

  changeActive(index) {
    this.setState({
      activeIndex: index
    });
  }

  render() {
    const {
      items,
      common,
    } = this.props;

    const {
      category,
      question,
      isQuestionShowed
    } = this.state;


    const categoryObj = items[category];

    const activeQuestion = categoryObj
      ? categoryObj.questions[question]
      : null
    ;

    return (
      <div className="faq-list">
        <TransitionGroup component={null}>
          {
            !isQuestionShowed
            ?
              <CSSTransition
                timeout={this.animationTime}
              >
                <Tabs
                  items={items}
                  common={common}
                  handleClick={this.answerClicked}
                  active={this.state.activeIndex}
                  topOffset={this.state.topOffset}
                  handleActive={this.changeActive}
                />
              </CSSTransition>
            : null
          }
        </TransitionGroup>
        <TransitionGroup component={null}>
          {
            isQuestionShowed
            ?
              (
                <CSSTransition
                  timeout={this.animationTime}
                >
                  <Details
                    title={activeQuestion.title}
                    items={this.getClosestQuestions(categoryObj)}
                    handleClick={this.setQuestion}
                    activeItem={activeQuestion}
                  />
                </CSSTransition>
              )
            : null
          }
        </TransitionGroup>
      </div>
    )
  }
};
