import React, { Component } from 'react';
import './Content.sass';

export default class Content extends Component {
  render() {
    const { activeItem } = this.props;
    const innerHtml = { __html: activeItem.content };
    return(
      <div className="details-content" dangerouslySetInnerHTML={innerHtml}></div>
    )
  }
}
