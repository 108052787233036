import React, { Component } from 'react';

import './TextBlock.sass';

export default class TextBlock extends Component {
  render() {
    return (
      <section className="text-block">
        <div className="text-block__in">
          <p>{this.props.text}</p>
        </div>
      </section>
    );
  }
};
