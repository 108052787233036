import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import classNames from 'classnames';

import Tooltip from 'components/Tootlip/Tooltip';

import './Nav.sass';

import { getLang } from 'Utils';

export default class Nav extends Component {
  constructor() {
    super();

    this.isScrolling = false;
    this.navRef = React.createRef();
  }

  handleScrollTo(e) {
    if(e.target.getAttribute('href').charAt(0) !== '/' || !e.target.getAttribute('href').startsWith('/#')) return;
    e.preventDefault();
    if (window.location.pathname !== `/${getLang()}/` && window.location.pathname !== '/') {
      window.location.href = `/${getLang()}${e.target.getAttribute('href')}`;
    }
    if(!e.target.getAttribute('href').startsWith('/#')) return;
    window.location.hash = e.target.getAttribute('href').substr(1);

    const id = e.target.getAttribute('href').substr(1);
    const section = id && document.querySelector(id);

    if (!section) return;

    const { offsetTop } = section;

    setTimeout(() => {
      window.scrollTo(0, offsetTop);
    }, 100)
  }

  insertTooltip(value) {
    return <Tooltip value={value} className="nav__tooltip" />;
  }

  handleClick() {
    window.scrollTo(0, 0);
  }

  createTooltipLink(item) {
    return (
      <span
        className="nav__item"
        key={item.title}
      >
        {item.title}
        {item.tooltip ? this.insertTooltip(item.tooltip) : null}
      </span>
    );
  }

  renderNavItem(item) {
    if (!item.url) return this.createTooltipLink(item);

    return item.url.charAt(0) === '/' && !item.url.startsWith('/#') ?
      <NavLink exact={true} className="nav__item" activeClassName="is-active" to={`/${getLang()}${item.url}`} key={item.title} onClick={this.handleClick}>
        {item.title}
      </NavLink>
      :
        <a
          className={classNames("nav__item", { "js-scroll-to": item.url.startsWith('/#') })}
          href={item.url}
          key={item.title}
          target={item.isExternal ? '_blank' : null}
          rel={item.isExternal ? 'noopener noreferrer' : null}
          onClick={this.handleScrollTo.bind(this)}
        >
          {item.title}
          {item.tooltip ? this.insertTooltip(item.tooltip) : null}
        </a>
      ;
  }

  render() {
    const { links } = this.props;

    return (
      <nav
        ref={this.navRef}
        className={classNames('nav', { 'nav_dark': this.props.isDark, [`${this.props.className}`]: this.props.className } )}
      >
        {links.map(item => this.renderNavItem(item))}
      </nav>
    );
  }
};
