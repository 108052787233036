import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import classNames from 'classnames';

import _throttle from 'lodash.throttle';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Home from 'pages/Home/Home';
import FAQ from 'pages/FAQ/FAQ';
import Careers from 'pages/Careers/Careers';
import About from 'pages/About/About';

import { updateViewportUnits, getLang, getPageDataUrl } from 'Utils';

const elementsDataUrl = getPageDataUrl('elements');

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      elementsData: null
    };
  }

  handleResize() {
    updateViewportUnits();
  }

  componentDidMount() {
    this.setData('elementsData', elementsDataUrl);
    updateViewportUnits();

    window.addEventListener('resize', _throttle(this.handleResize.bind(this), 200));
  }

  async setData(stateKey, url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Something goes wrong');
      }
      const myJson = await response.json();
      this.setState({
        [stateKey]: myJson[0].acf
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  render() {
    return (
              <Router>
                <div className={
                  classNames(
                    'app',
                    {
                      [`is-${getLang()}`]: getLang()
                    }
                  )
                }>
                  {this.state.elementsData
                    ? <Header
                        links={this.state.elementsData.nav}
                        mobLinks={this.state.elementsData.mob.menu}
                        join={this.state.elementsData.join}
                      />
                    : ''}
                    <div className="app__body">
                      <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/faq" component={FAQ} exact />
                        <Route path="/careers" component={Careers} exact />
                        <Route path="/about" component={About} exact />
                        <Route path={`/${getLang()}`} component={Home} exact />
                        <Route path={`/${getLang()}/faq`} component={FAQ} exact />
                        <Route path={`/${getLang()}/careers`} component={Careers} exact />
                        <Route path={`/${getLang()}/about`} component={About} exact />
                      </Switch>
                    </div>
                  {this.state.elementsData
                    ? <Footer
                        links={this.state.elementsData.footer.links}
                        nav={this.state.elementsData.footer.nav}
                        mob={this.state.elementsData.mob}
                      />
                    : ''}
                </div>
              </Router>
    );
  }
};
