import React, { Component } from 'react';

import { getPageDataUrl } from 'Utils';

import { default as FAQSection } from 'sections/FAQ/FAQ';
import JoinUs from 'sections/JoinUs/JoinUs';

import './FAQ.sass';

const pageDataUrl = getPageDataUrl('faq');
const elementsDataUrl = getPageDataUrl('elements');

export default class FAQ extends Component {
  constructor() {
    super();

    this.state = {
      pageData: null,
      elementsData: null,
    };
  }

  componentDidMount() {
    this.setData('pageData', pageDataUrl);
    this.setData('elementsData', elementsDataUrl);
  }

  async setData(stateKey, url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Something goes wrong');
      }
      const myJson = await response.json();
      this.setState({
        [stateKey]: myJson[0].acf
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  render() {
    return (
      this.state.pageData && this.state.elementsData
      ? <div className="faq">
          <div className="faq__in">
            <FAQSection
              items={this.state.pageData.list}
              common={this.state.pageData.common}
            />
          </div>

          <JoinUs
            allScreensShown={true}
            headline={this.state.elementsData.join.headline}
            subscribe={this.state.elementsData.join.subscribe}
            partner={this.state.elementsData.join.partner}
            comunity={this.state.elementsData.join.comunity}
          />
        </div>
      : ''
    )
  }
};
