import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './Logo.sass';

export default class Logo extends Component {
  render() {
    return(
      <Link
        to={this.props.href || '/'}
        className={classNames(['logo', this.props.className || null])}
      >
        <img src={this.props.src} alt={this.props.alt} />
      </Link>
    );
  }
};
