import React, { Component } from 'react';
import classNames from 'classnames';

import langs from 'data/langs.json';
import { getLang } from 'Utils';

import './Lang.sass';

import { ReactComponent as Icon } from 'icons/arrow-down.svg';

export default class Lang extends Component {
  constructor() {
    super();

    this.state = {
      current: getLang(),
      opened: false
    };

    this.ref = React.createRef();

    this.classes = {
      block: 'is-blocked'
    };

    // this.changeLanguage = this.changeLanguage.bind(this);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside({ target }) {
    const { current: node } = this.ref;
    if (node && !node.contains(target)) this.close();
  }

  close() {
    this.setState({
      opened: false
    });

    document.body.classList.remove(this.classes.block);
  }

  handleBtnClick() {
    this.setState({
      opened: !this.state.opened
    });

    document.body.classList.toggle(this.classes.block);
  }

  render() {
    const { current, opened } = this.state;

    const page = window.location.pathname.split('/')[1].length === 2 ? window.location.pathname.split('/')[2] : window.location.pathname.split('/')[1];

    return (
      <div className={classNames('lang', { 'is-opened': opened })} ref={this.ref}>
        {
          opened ?
            <div className="lang__links">
              <button className="lang__close" onClick={this.close}></button>
              <ul className="lang__list">
                {
                  Object.keys(langs).map((item) =>
                      <li key={item} className="lang__item">
                        <a
                          href={`/${item}/${page}`}
                          className={
                            classNames('lang__link', {
                              'is-active': item === current
                            })
                          }
                        >{langs[item]}</a>
                      </li>
                    )
                }
              </ul>
            </div>
              : null
        }
        <div className="lang__btn" onClick={this.handleBtnClick}>
          <div className="lang__current">
            {current.toUpperCase()}
          </div>
          <div className="lang__icon">
            <Icon />
          </div>
        </div>
      </div>
    );
  }
};
