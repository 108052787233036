import React, { Component } from 'react';
import classNames from 'classnames';

import './AboutCard.sass';

export default class AboutCard extends Component {
	render() {
		const {
			suptitle,
			title,
			subtitle,
			smallTitle
		} = this.props;

		return (
			<div className="about-card">
				{suptitle ? <div className="about-card__suptitle">{suptitle}</div> : ''}
				<div className={classNames('about-card__title', {
					'about-card__title_sm': smallTitle
				})}>
					{title}
				</div>
				<div className="about-card__subtitle">
					{subtitle}
				</div>
			</div>
		)
	}
}
