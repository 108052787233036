const url = document.body.dataset.url;

export const hasClass = (el, className) => el.classList.contains(className);

export const updateViewportUnits = () => {
  document.body.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  document.body.style.setProperty('--vw', `${window.innerWidth * 0.01}px`);
};

export const getPageDataUrl = (pageName) => {
  const lng = getLang();
  return `${url}/api/${lng}/wp-json/wp/v2/pages/?slug=${pageName}`
};

export const getLang = () => {
  return window.location.pathname.split('/')[1].length === 2 ? window.location.pathname.split('/')[1] : 'en';
};
