import React, { Component } from 'react';
import { ReactComponent as Angle } from 'icons/angle-right-light.svg';

import './Answer.sass';

export default class Answer extends Component {
  constructor() {
    super();
    this.slideToggle = this.slideToggle.bind(this);
  }

  slideUp() {
    this.toggleContent.style.height = 0;
    this.toggleContent.style.opacity = 0;
    this.toggleItem.classList.remove('is-active');
  }
  slideDown() {
    this.toggleContent.style.height = `${this.toggleContent.scrollHeight}px`;
    this.toggleContent.style.opacity = 1;
    this.toggleItem.classList.add('is-active');
  }

  slideToggle() {
    const currentHeight = this.toggleContent.offsetHeight;
    if (!currentHeight) {
      this.slideDown();
    } else {
      this.slideUp();
    }
  }

  render() {
    const {
      title,
      text,
      hasFullAnswer,
      common
    } = this.props;

    return (
      <div className="faq-answer" ref={ toggleItem => this.toggleItem = toggleItem }>

          <div className="faq-answer__title" onClick={e => this.slideToggle(e)}>{title}</div>
          <div
            className="faq-answer__toggle"
            ref={ toggleContent => this.toggleContent = toggleContent }>
            <div className="faq-answer__text">{text}</div>
            {
              hasFullAnswer
              ?
                <a className="faq-answer__full-link" href="/" onClick={this.props.handleClick}>
                  {common.buttons.more}
                  <Angle/>
                </a>
              : null
            }
          </div>

        <div className="faq-answer__icon" onClick={e => this.slideToggle(e)}>
          <Angle/>
        </div>
      </div>
    )
  }
};
