import React, { Component } from 'react';

import Nav from 'components/Nav/Nav';
import Subscribe from 'components/Subscribe/Subscribe';
import Button from 'components/Button/Button';
import Socials from 'components/Socials/Socials';

import './MenuMob.sass';

export default class MenuMob extends Component {
  render() {
    const {
      join,
      menu
    } = this.props;

    return (
      <div className="menu-mob">
          <div className="menu-mob__in">
            <div className="menu-mob__body" onClick={this.props.onCloseMenu}>
              <Nav links={menu} />
            </div>
            <div className="menu-mob__footer">
              <div className="menu-mob__btn">
                <Button
                  color="violet"
                  title={join.partner.btn.title}
                  url={`mailto:${join.partner.btn.url}`}
                  size="sm"
                />
              </div>
              <div className="menu-mob__socials">
                <Socials links={join.comunity.socials.map(item => item.item)} />
              </div>
            </div>
            <Subscribe {...join.subscribe.form} />
          </div>
      </div>
    );
  }
};
