import React, { Component, createRef } from 'react';
import classNames from 'classnames';

import './Benefits.sass';
import { TweenLite } from 'gsap/all';

export default class Benefits extends Component {
  constructor() {
    super();

    this.benefits = [
      'violet',
      'green',
      'pink',
      'blue',
      'dark-blue',
      'orange'
    ];

    this.headline = createRef();
    this.video = createRef();

    this.playVideo = this.playVideo.bind(this);
  }

  videoPaused() {
    TweenLite.to(this.video.current, 0.3, { autoAlpha: 0 });
  }

  videoPlayed() {
    TweenLite.to(this.video.current, 0.3, { autoAlpha: 1 });
  }

  setVideoHandlers() {
    const {
      current: video
    } = this.video;

    video.addEventListener('play', this.videoPlayed.bind(this));
    video.addEventListener('pause', this.videoPaused.bind(this));

    TweenLite.set(this.video.current, { autoAlpha: 0 });
  }

  componentDidMount() {
    this.setVideoHandlers();
  }

  playVideo(e) {
    const {
      current: video
    } = this.video;

    const { target } = e;

    if (video.paused) {
      video.play(0);
      target.classList.add('is-active');
    } else {
      video.pause();
      target.classList.remove('is-active');
    }
  }

  getHeadline(headline, index) {
    const color = this.benefits[index % this.benefits.length];

    return (
      <h3
        className={
          classNames(
            "benefits__headline",
            "js-benefits-headline",
            {
              "benefits__headline_violet": !color || color === 'violet',
              "benefits__headline_green": color === 'green',
              "benefits__headline_pink": color === 'pink',
              "benefits__headline_blue": color === 'blue',
              "benefits__headline_dark-blue": color === 'dark-blue',
              "benefits__headline_orange": color === 'orange'
            }
          )
        }
      >
        <span>{headline}</span>
        <span>{headline}</span>
        <span>{headline}</span>
      </h3>
    );
  }

  createItem(options, index) {
    const {
      headline,
      text,
      icons,
      mob
    } = options;

    const iconsCollection = icons.map(item => <img src={item.icon.url} alt={item.icon.alt} key={item.icon.alt} className="benefits__icon" />);

    return (
      <div className="benefits__item js-benefits-item" key={headline}>
        <div className="benefits__row">
          <div className="benefits__header">
            {this.getHeadline(headline, index)}
            {
              mob && mob.video && mob.video.url
                ?
                  (
                    <div className="benefits__promo">
                      <div className="benefits__played-video">
                        <video src={mob.video.url} preload="none" ref={this.video}></video>
                      </div>
                      <div className="benefits__play-btn" onClick={this.playVideo}></div>
                    </div>
                  )
                : null
              }
          </div>
          <p className="benefits__text">{text}</p>
        </div>
        {
          iconsCollection.length
            ? (
              <div className="benefits__icons">
                {iconsCollection.map(item => item)}
              </div>
            )
            : null
        }
      </div>
    );
  }

  render() {
    return (
      <section className="benefits js-scene" data-scene-name="benefits">
        <div className="benefits__video js-benefits-video">
          <video data-src="/video/dragon.mp4" preload="metadata" muted autoPlay></video>
        </div>
        {this.props.items.map((item, index) => this.createItem(item, index))}
      </section>
    );
  }
};
