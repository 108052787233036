import React, { Component, createRef } from 'react';

import _throttle from 'lodash.throttle';

import './Header.sass';

import Logo from 'components/Logo/Logo';
import Nav from 'components/Nav/Nav';
import Lang from 'components/Lang/Lang';
import Burger from 'components/Burger/Burger';
import MenuMob from 'components/MenuMob/MenuMob';

import { hasClass } from 'Utils';

import { TweenLite, Power2 } from 'gsap/all';

export default class Header extends Component {
  constructor() {
    super();

    this.classes = {
      active: 'is-active',
      fixed: 'is-fixed',
      mobMenuOpened: 'is-mob-menu-opened',
      block: 'is-blocked'
    };

    this.state = {
      isMobMenuActive: false
    };

    this.ref = createRef();
    this.burger = createRef();

    this.setListeners = this.setListeners.bind(this);
    this.handleBurgerClick = this.handleBurgerClick.bind(this);

    this.scrollPos = 0;
  }

  handleBurgerClick() {
    this.setState({
      isMobMenuActive: !this.state.isMobMenuActive
    }, () => {
      if (this.state.isMobMenuActive) {
        document.body.classList.add(this.classes.mobMenuOpened);
        document.body.classList.add(this.classes.block);
      } else {
        document.body.classList.remove(this.classes.mobMenuOpened);
        document.body.classList.remove(this.classes.block);
      }
    });
  }

  setHeaderToDefault(state, el) {
    if (!state) return;
    el.classList.remove(this.classes.fixed);
  }

  setActiveHeader(state, el) {
    if (state) return;
    el.classList.add(this.classes.fixed);
  }

  stopHeaderTimeline() {
    if (this.headerTl) this.headerTl.pause();
  }

  headerToDefault(header) {
    const { fixed } = this.classes;
    const isOnTop = window.pageYOffset <= 0;

    this.headerTl = TweenLite.to(
      header,
      0.3,
      {
        top: () => isOnTop ? 0 : -header.offsetHeight,
        className: `-=${fixed}`,
        ease: Power2.easeOut
      }
    );
  }

  headerToFixed(header) {
    const { fixed } = this.classes;

    if (hasClass(header, fixed)) return;
    this.stopHeaderTimeline();

    this.headerTl = TweenLite.to(
      header,
      0.3,
      {
        top: 0,
        className: `+=${fixed}`,
        ease: Power2.easeOut
      }
    );
  }

  handleScroll() {
    const { current } = this.ref;
    const { scrollY } = window;

    if (this.state.isMobMenuActive) {
      this.setHeaderToDefault();
      return;
    }

    if (this.scrollPos > scrollY) {
      window.pageYOffset >= 0 ? this.headerToFixed(current) : this.headerToDefault(current);
    } else if (this.scrollPos < scrollY) {
      this.headerToDefault(current);
    }

    this.scrollPos = scrollY;
  }

  setListeners() {
    window.addEventListener('scroll', _throttle(this.handleScroll.bind(this), 200));
  }

  closeMenu() {
    this.handleBurgerClick();
  }

  componentDidMount() {
    this.setListeners();
  }

  render() {
    const { links, mobLinks, join } = this.props;

    return (
      <header className="header" ref={this.ref}>
        <Logo className="header__logo" src="/img/logo.svg" alt="Logo" />
        <div className="header__nav">
          <Nav links={links} />
        </div>
        <div className="header__btns">
          <Lang />
          <div
            className="header__burger"
            onClick={this.handleBurgerClick}
            ref={this.burger}
          >
            <Burger active={this.state.isMobMenuActive} />
          </div>
        </div>
        <MenuMob menu={mobLinks} join={join} onCloseMenu={this.closeMenu.bind(this)}/>
      </header>
    );
  }
};
