import React, { Component } from 'react';

import Circles from 'components/Demo/Circles/Circles';

import { ReactComponent as Search } from 'svg/search.svg';
import { ReactComponent as SendMoney } from 'svg/send-money.svg';
import { ReactComponent as SendDone } from 'svg/send-done.svg';

import './Screen.sass';

export default class Screen extends Component {
  getLastMobileScreen() {
    const {
      status,
      earned
    } = this.props.interface;

    return (
      <div className="screen__item screen__item_last" ref={this.props.lastScene}>
        <div className="screen__status-title">{status}</div>
        <div className="screen__logo" ref={this.props.logo}></div>
        <div className="screen__points">
          <div className="screen__points-title">{earned}</div>
          <div className="screen__point" data-min="0" data-max="2500" ref={this.props.points}>0 / 2500</div>
          <div className="screen__line">
            <span className="screen__progress" ref={this.props.progress}></span>
          </div>
        </div>
      </div>
    );
  }

  getSixthMobileScreen() {
    const {
      eex_price_title,
      market_cup,
      chart_dates
    } = this.props.interface;

    return (
      <div className="screen__item screen__item_sixth">
        <span className="screen__price">$ 35.00</span>
        <span className="screen__market-cap">$ 350,000,000.00</span>
        <span className="screen__eex-price-title">{eex_price_title}</span>
        <span className="screen__market-cap-title">{market_cup}</span>
        <span className="screen__date">
          {chart_dates.map(item => <span key={item.chart_date}>{item.chart_date}</span>)}
        </span>
      </div>
    );
  }

  getThirdMobileScreen() {
    const { search } = this.props;

    const {
      search_msg,
      shake_msg,
      send,
      cancel,
      sent,
      done,
      back,
      ok
    } = this.props.interface;

    return (
      <div className="screen__item screen__item_third">
        <div className="screen__circles">
          <Circles count={5} />
        </div>
        <div className="screen__search-icon" ref={search.icon}>
          <Search />
        </div>
        <div className="screen__search-footer" ref={search.footer}>
          <span>{search_msg}</span>
          <span>{shake_msg}</span>
        </div>
        <div className="screen__user screen__user_first" ref={search.user}>
          <span>Leo Chapman</span>
        </div>
        <div className="screen__user screen__user_second" ref={search.foundedUser}>
          <span>Violette Miers</span>
        </div>
        <div className="screen__send-money" ref={search.send}>
          <SendMoney />
          <span className="screen__send-money-btn">{send}</span>
          <span className="screen__send-money-cancel">{cancel}</span>
        </div>
        <div className="screen__done" ref={search.done}>
          <SendDone />
          <span className="screen__done-headline">{sent}</span>
          <span className="screen__done-status">{done}</span>
          <span className="screen__done-btn">{back}</span>
          <span className="screen__done-ok">{ok}</span>
        </div>
      </div>
    );
  }

  getSecondMobileScreen() {
    const {
      balance,
      btc,
      buy,
      sell,
      price
    } = this.props.interface;

    return (
      <div className="screen__item screen__item_second">
        <span className="screen__eex-title">{balance}</span>
        <span className="screen__eex">18.34840</span>
        <span className="screen__btc screen__btc_first">{btc}</span>
        <span className="screen__btc screen__btc_second">{btc}</span>
        <span className="screen__eex-btn screen__eex-btn_buy">{buy}</span>
        <span className="screen__eex-btn screen__eex-btn_sell">{sell}</span>
        <span className="screen__eex-price screen__eex-price_first">{price}: 0.04841111 BTC</span>
        <span className="screen__eex-price screen__eex-price_second">{price}: 0.04841111 BTC</span>
      </div>
    );
  }

  getFirstMobileScreen() {
    const {
      balance,
      sent_title,
      received_title,
      wallet_dates,
      in_progress,
      confirmed
    } = this.props.interface;

    const walletDatesArray = wallet_dates.map(item => item.wallet_date);

    return (
      <div className="screen__item screen__item_first">
        <span className="screen__eex-title">{balance}</span>
        <span className="screen__eex" data-min="0" data-max="18.34840" ref={this.props.eex}>0</span>
        <span className="screen__eex-status-title screen__eex-status-title_first">{sent_title}</span>
        <span className="screen__eex-status-title screen__eex-status-title_second">{sent_title}</span>
        <span className="screen__eex-status-title screen__eex-status-title_third">{received_title}</span>
        <span className="screen__status screen__status_first">
          <span>{walletDatesArray[0]}</span>
          <span>{in_progress}</span>
        </span>
        <span className="screen__status screen__status_second">
          <span>{walletDatesArray[1]}</span>
          <span>{confirmed}</span>
        </span>
        <span className="screen__status screen__status_third">
          <span>{walletDatesArray[2]}</span>
          <span>{confirmed}</span>
        </span>
      </div>
    );
  }

  render() {
    const { isEmpty } = this.props;

    return isEmpty
      ?
        (
          <div className="screen screen_static" ref={this.props.phoneStaticScreen}>
            <div className="screen__bar"></div>
          </div>
        )
      :
        (
          <div className="screen is-first-screen" ref={this.props.phoneScreen}>
            <div className="screen__curtain" ref={this.props.curtain}></div>
            <div className="screen__bar"></div>
            <div className="screen__circle screen__circle_back" ref={this.props.circleBack}></div>
            <div className="screen__headline">
              <div className="screen__btn"></div>
              <div className="screen__title">
                <span>{this.props.interface.wallet}</span>
                <span>{this.props.interface.shake_title}</span>
                <span>{this.props.interface.achievements}</span>
              </div>
              <div className="screen__avatar" ref={this.props.avatar}></div>
            </div>
            <div className="screen__circle" ref={this.props.circle}></div>
            <div className="screen__balance" ref={this.props.balance}>
              {this.getFirstMobileScreen()}
              {this.getSecondMobileScreen()}
              {this.getThirdMobileScreen()}
              {this.getSixthMobileScreen()}
              {this.getLastMobileScreen()}
            </div>
          </div>
        )
    ;
  }
};
