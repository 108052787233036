import React, { Component } from 'react';
import Content from 'components/Details/Content/Content';
import Header from 'components/Details/Header/Header';
import Sidebar from 'components/Details/Sidebar/Sidebar';

import './Details.sass';

export default class Details extends Component {

  render() {
    const {
      title,
      items,
      activeItem,
      sidebarTitle,
      handleClick
    } = this.props;

    return (
      <div className="faq-details">
        <Header
          handleClick={handleClick}
          title={title}/>
        <div className="faq-details__in">
          <div className="faq-details__body">
            <Content activeItem={activeItem}/>
          </div>

          <div className="faq-details__sidebar">
            <Sidebar
              sidebarTitle={sidebarTitle}
              items={items}
              activeItem={activeItem}
              handleClick={handleClick}
            />
          </div>
        </div>
      </div>
    )
  }
}
