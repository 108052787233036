import React, { Component } from 'react';
import classNames from "classnames";

import './AboutItem.sass';

export default class AboutItem extends Component {
	render() {
		const {
			color,
			title,
			text
		} = this.props;

		const innerHtml = { __html: text };

		return (
			<div className={classNames('about-item', `about-item_${color}`)} data-aos="fade-up">
				<div className="about-item__title">
					{title}
				</div>
				<div className="about-item__text" dangerouslySetInnerHTML={innerHtml}></div>
			</div>
		)
	}
}
