import React, { Component } from 'react';
import classNames from 'classnames';

import './Sidebar.sass';

export default class Sidebar extends Component {

  handleClick(e, index) {
    e.preventDefault();

    window.scrollTo(0, 0);

    this.props.handleClick(index);
  }


  render() {
    const {
      sidebarTitle,
      items,
      activeItem
    } = this.props;

    return(
      <div className="details-sidebar">
        {sidebarTitle ? <div className="details-sidebar__title">{sidebarTitle}</div> : null}
        <div className="details-sidebar__links">
          {
            items.map((item, index) =>
              <a
                className={classNames('details-sidebar__link', activeItem.index === items[index].index ? 'is-active' : '')}
                href="/" key={item.title+item.index}
                onClick={e => this.handleClick(e, item.index)}
              >
                {item.title}
              </a>
            )
          }
        </div>
      </div>
    )
  }
}
