import React, { Component } from 'react';

import _throttle from 'lodash.throttle';

import './Roadmap.sass';

import { ReactComponent as Pin } from 'icons/pin.svg';

export default class Roadmap extends Component {
  constructor() {
    super();

    this.state = {
      progress: 16.2,
      offset: 150
    };

    this.handleResizeThrottle = _throttle(this.handleResize.bind(this), 200);
  }

  handleResize() {
    this.calcRoadmapPos();
  }

  calcRoadmapPos() {
    const containers = [...document.querySelectorAll('.js-roadmap-item')];
    const titles = [...document.querySelectorAll('.js-roadmap-title')];

    const offset = window.innerWidth >= 1024 ? 106 : 0;

    const { active } = this.props;

    const progress = containers[active - 1]
      ? containers[active - 1].offsetTop + titles[active - 1].offsetHeight + offset
      : this.ref.current.offsetHeight + offset * 0.5
    ;

    this.setState({
      progress,
      offset
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeThrottle);
    window.removeEventListener('orientationchange', this.handleResizeThrottle);
  }

  componentDidMount() {
    this.calcRoadmapPos();

    window.addEventListener('resize', this.handleResizeThrottle);

    window.addEventListener('orientationchange', this.handleResizeThrottle);
  }

  render() {
    const { items } = this.props;

    return (
      <section className="roadmap js-scene js-roadmap" data-scene-name="roadmap">
        <div className="roadmap__side">
          <h3 className="roadmap__headline js-roadmap-headline">{this.props.headline}</h3>
        </div>
        <div className="roadmap__side">
          <div className="roadmap__steps">
            <div
              className="roadmap__progress"
              style={{
                height: `${this.state.progress}px`,
                top: `-${this.state.offset}px`
              }}
            ></div>
            {
              items.map((item, index) => (
                <div
                  className="roadmap__item js-roadmap-item"
                  key={item.title}
                >
                  <h4 className="roadmap__title js-roadmap-title">{item.title}</h4>
                  {
                    item.text
                      ? (
                        <p className="roadmap__text">
                          {item.text}
                          {
                            items.length - 1 === index
                              ? <span className="roadmap__icon"><Pin /></span>
                              : null
                          }
                        </p>
                      )
                      : null
                  }
                </div>
              ))
            }
          </div>
        </div>
      </section>
    )
  }
};
