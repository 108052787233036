import React, { Component, createRef } from 'react';

import { TimelineMax, TweenLite, TimelineLite, Linear } from 'gsap/all';
import ScrollMagic from 'scrollmagic';

import _throttle from 'lodash.throttle';
import 'animation.gsap';


import './Demo.sass';

import Circles from 'components/Demo/Circles/Circles';
import Item from 'components/Demo/Item/Item';
import Screen from 'components/Demo/Screen/Screen';

export default class Demo extends Component {
  constructor() {
    super();

    this.items = [];

    this.controller = new ScrollMagic.Controller();

    this.handleSceneUpdate = this.handleSceneUpdate.bind(this);
    this.handleResizeThrottle = _throttle(this.handleResize.bind(this), 200);

    this.setItems();
  }

  hideCoin() {
    const {
      coin,
      shadowCoin
    } = this;

    TweenLite.set([coin, shadowCoin], { autoAlpha: 0 });
  }

  showCoin() {
    const {
      coin,
      shadowCoin
    } = this;

    TweenLite.set([coin, shadowCoin], { autoAlpha: 1 });
  }

  handleSceneUpdate() {
    this.showCoin();
  }

  UNSAFE_componentWillUpdate() {
    // to fix bug with coin
    this.hideCoin();

    this.controller.scrollTo(2);
    this.controller.scrollTo(0);

    setTimeout(() => this.showCoin(), 100);
  }

  getSearchIconAnimation() {
    return new TimelineMax({
      repeat: -1,
      repeatDelay: 0,
      delay: 0,
      paused: true
    })
      .to(
        '#screen-search',
        1.2,
        {
          bezier: {
            type: 'quadratic',
            values: [
              { x: 0, y: 0 }, { x: 3, y: 0 }, { x: 3, y: 3 },
              { x: 3, y: 6 }, { x: 0, y: 6 },
              { x: -3, y: 6 }, { x: -3, y: 6 },
              { x: -3, y: 0 }, { x: 0, y: 0 }
            ],
            autoRotate: false
          },
          ease: Linear.easeNone
        }
      )
    ;
  }

  handleResize() {
    this.setCSSWidthProp();

    this.tl = this.getScreensTl();

    this.scene
      .removeTween(true)
      .setTween(this.tl)
      .refresh()
      .update(true)
    ;
    this.controller.update();
  }

  componentDidUpdate() {
    const progress = this.scene.progress();

    this.scene.destroy(true);
    this.createScene();
    this.controller.update(true);

    this.scene.progress(progress);
  }

  componentWillUnmount() {
    this.items = [];

    this.controller.destroy(true);
    this.scene.destroy(true);
    this.tl.kill();

    window.removeEventListener('resize', this.handleResizeThrottle);
  }

  componentDidMount() {
    this.createScene();
    this.setCSSWidthProp();

    this.counter = this.getCounter();
    this.searchAnimation = this.getSearchIconAnimation();

    window.addEventListener('resize', this.handleResizeThrottle);
  }

  setItems() {
    this.interfaceRefs = {
      circle: createRef(),
      circleBack: createRef(),
      phoneScreen: createRef(),
      curtain: createRef(),
      balance: createRef(),
      logo: createRef(),
      progress: createRef(),
      points: createRef(),
      avatar: createRef(),
      eex: createRef(),
      lastScene: createRef(),
      phoneStaticScreen: createRef()
    }

    this.searchRefs = {
      footer: createRef(),
      user: createRef(),
      foundedUser: createRef(),
      icon: createRef(),
      send: createRef(),
      done: createRef()
    };
  }

  setCSSWidthProp() {
    const {
      width
    } = this.contentWrapp.getBoundingClientRect();

    const {
      width: phoneWidth
    } = this.phoneBody.getBoundingClientRect();

    const {
      width: phoneSideWidth
    } = this.phoneSide.getBoundingClientRect();

    const offsetX = width + phoneSideWidth - phoneWidth;
    window.root.style.setProperty(
      '--line-width',
      offsetX + 'px'
    );
  }

  getCounter() {
    const logo = this.interfaceRefs.logo.current;
    const points = this.interfaceRefs.points.current;
    const progress = this.interfaceRefs.progress.current;

    const { max } = points.dataset;

    const level = {
      max,
      value: 0
    };

    return new TimelineLite({
      paused: true
    })
      .to(
        logo,
        2,
        {
          rotation: 360,
          scale: 1
        },
        0
      )
      .to(logo, 2, { className: '+=is-updated' }, '-=1')
      .fromTo(
        progress,
        2,
        {
          scaleX: 0,
          value: 0
        },
        {
          scaleX: 1,
          transformOrigin: 'top left',
          value: max
        },
        0
      )
      .fromTo(
        level,
        2,
        {
          value: 0,
        },
        {
          value: level.max,
          onUpdate: () => points.innerHTML = `${Math.ceil(level.value)} / ${Math.ceil(level.max)}`
        },
        0
      )
    ;
  }

  getLastSceneTimeline() {
    const {
      lastScene
    } = this.interfaceRefs;

    return new TimelineMax({
      onStart: () => {
        if (this.isDragonShown) return;
        this.isDragonShown = true;

        this.counter.play(0);
      }
    })
      .to(lastScene.current, 0.3, { visibility: 'visible' })
    ;
  }

  getThirdScreenTimeline() {
    const {
      footer,
      user,
      foundedUser,
      icon,
      send,
      done
    } = this.searchRefs;

    const {
      phoneSide
    } = this;

    return new TimelineMax({
      onStart: () => this.searchAnimation.play(0),
      onComplete: () => this.searchAnimation.pause()
    })
      .fromTo(footer.current, 120, { y: '100%' }, { y: '0%' })
      .fromTo(user.current, 100, { autoAlpha: 0 }, { autoAlpha: 1 }, '+=40')
      .fromTo(foundedUser.current, 100, { autoAlpha: 0, scale: 1 }, { autoAlpha: 1, scale: 1.2 })
      .to(foundedUser.current, 100, { scale: 1 })
      .to([icon.current, user.current], 100, { autoAlpha: 0 })
      .to(footer.current, 100, { y: '100%' }, '-=100')
      .to(foundedUser.current, 120, { top: '26%', right: '50%', x: '50%' })
      .fromTo(send.current, 120, { y: '100%' }, { y: '0%' }, '-=120')
      .to(foundedUser.current, 100, { y: '-100%', autoAlpha: 0 }, '+=80')
      .to(send.current, 100, { y: '100%', autoAlpha: 0 }, '-=100')
      .fromTo(done.current, 100, { autoAlpha: 0 }, { autoAlpha: 1 }, '+=80')
      .fromTo('#done-check', 100, { autoAlpha: 0, scale: 0 }, { autoAlpha: 1, scale: 1.2, transformOrigin: 'center' })
      .to(phoneSide, 80, { className: '+=is-circles-animating' }, '-=70')
      .to('#done-check', 80, { scale: 1 })
      .to(phoneSide, 80, { className: '+=is-circles-hidden' }, '+=100')
    ;
  }

  getPhoneScreensTl(items) {
    const scenesClasses = [
      'is-first-screen',
      'is-second-screen',
      'is-third-screen',
      'is-fourth-screen',
      'is-fifth-screen'
    ];

    const animations = {
      '3': this.getThirdScreenTimeline()
    };

    const phoneScreen = this.interfaceRefs.phoneScreen.current;

    const tl = new TimelineMax({
      paused: true
    });

    items.forEach((item, index) => {
      const delay = index ? 300 : 1;

      tl.add(
        new TimelineLite()
          .fromTo(item, 60, { autoAlpha: 0 }, { autoAlpha: 1 }, `-=${delay}`)
          .add(animations[index] || '')
          .to(item, 40, { autoAlpha: 0 }, '+=200')
          .to(phoneScreen, 0.1, { className: `+=${scenesClasses[index]}` })
          .to(phoneScreen, 0.1, { className: `-=${index === scenesClasses.length ? '' : scenesClasses[index - 1]}`})
        )
      ;
    });

    return tl;
  }

  playCounter() {
    const eex = this.interfaceRefs.eex.current;
    if (!eex) return;

    const { min, max } = eex.dataset;
    const current = {
      value: 0
    };

    TweenLite.fromTo(
      current,
      1,
      {
        value: min
      },
      {
        value: max,
        onUpdate: () => eex.innerHTML = (current.value).toFixed(5)
      }
    );
  }

  getTimeline() {
    const {
      coin,
      dotts,
      shadowCoin,
      phone,
      items,
      footer,
      containerIn,
      phoneBody
    } = this;

    const circle = this.interfaceRefs.circle.current;
    const circleBack = this.interfaceRefs.circleBack.current;

    const phoneStaticScreen = this.interfaceRefs.phoneStaticScreen.current;
    const phoneScreen = this.interfaceRefs.phoneScreen.current;

    const curtain = this.interfaceRefs.curtain.current;
    const balance = this.interfaceRefs.balance.current;
    const avatar = this.interfaceRefs.avatar.current;

    const getCoinPosY = () => {
      const { clientHeight: height } = this.container;
      const { offsetHeight: phoneHeight } = this.phone;
      const { offsetHeight: coinHeight } = coin;

      return height - (phoneHeight + coinHeight) * 0.5;
    };

    const getPhonePosX = () => {
      const { offsetWidth: width } = this.container;
      const { offsetWidth: phoneWidth } = this.phone;

      const isPortrait = window.innerHeight > window.innerWidth;
      const isTablet = window.innerWidth <= 1024;

      const paddingLeft = parseInt(window.getComputedStyle(containerIn, null).getPropertyValue('padding-left'));

      return (isTablet && isPortrait) ? 0 : (width - phoneWidth) * 0.5 - paddingLeft;
    };

    return new TimelineMax()
      .fromTo([phoneBody, phoneScreen], 60, { y: () => this.container.clientHeight }, { y: 0 })
      .fromTo([coin, shadowCoin], 120, { y: 0 }, { y: () => getCoinPosY() }, 0)
      .fromTo(coin, 60, { scale: 1 }, { scale: 1.2, onStart: this.playCounter.bind(this) })
      .fromTo(dotts, 15, { autoAlpha: 0 }, { autoAlpha: 1 }, '-=15')
      .fromTo(phoneStaticScreen, 0.1, { autoAlpha: 0 }, { autoAlpha: 1 })
      .fromTo(circle, 20, { scale: 0 }, { scale: 1 })
      .fromTo(circleBack, 15, { scale: 0 }, { scale: 1.2 })
      .fromTo(circleBack, 15, { borderRadius: '50%' }, { borderRadius: '0%' })
      .fromTo(phone, 20, { x: () => getPhonePosX() * -1 }, { x: 0 })
      .fromTo([coin, shadowCoin], 20, { x: 0 }, { x: () => getPhonePosX()}, '-=20')
      .fromTo(footer, 1, { autoAlpha: 0 }, { autoAlpha: 1 })
      .fromTo(curtain, 20, { scaleY: 0 }, { scaleY: 1, transformOrigin: 'top left' }, '+=30')
      .to(dotts, 10, { autoAlpha: 0 }, '-=10')
      .fromTo(balance, 10, { y: '-18%' }, { y: '-78%' }, '-=10')
      .to([coin, shadowCoin, circle], 5, { autoAlpha: 0 }, '-=10')
      .to(avatar, 20, { className: '+=is-colored' })
      .add(this.getPhoneScreensTl(items).play(), '-=60')
    ;
  }

  getScreensTl() {
    const tl = this.getTimeline();
    const lastScreenTl = this.getLastSceneTimeline();

    const animation = new TimelineMax()
      .add(tl)
      .add(lastScreenTl.play(), '-=520')
    ;

    return animation;
  }

  createScene() {
    this.tl = this.getScreensTl();

    this.scene = new ScrollMagic.Scene({
      triggerElement: '#demo',
      duration: window.innerHeight * 8,
      triggerHook: 0
    })
      .setPin('#demo', { pushFollowers: true })
      .setTween(this.tl)
      .addTo(this.controller)
    ;
  }

  generateItems() {
    this.items = [];

    return this.props.items.map((item, index) => {
      const iconsArray = item.icons && item.icons.map(item => item.icon.url);
      return (
        <Item
          title={item.title}
          text={item.text}
          icons={iconsArray}
          key={item.title}
          ref={current => current ? this.items.push(current.node) : null}
        />
      )
    });
  }

  createScreen(isEmpty) {
    return isEmpty
      ? <Screen isEmpty={true} phoneStaticScreen={this.interfaceRefs.phoneStaticScreen} />
        :
          <Screen
            interface={this.props.interface}
            search={this.searchRefs}
            {...this.interfaceRefs}
          />
    ;
  }

  render() {
    return (
      <section id="demo" className="demo" ref={node => this.container = node}>

        <div id="demo-pin" className="demo__in" ref={node => this.containerIn = node}>
          <div className="demo__side demo__side_content" ref={node => this.contentWrapp = node}>
            <div
              className="demo__content"
              ref={node => this.content = node}
            >
              {this.generateItems()}
              <div className="demo__footer" ref={node => this.footer = node}>
                <p>{this.props.footer}</p>
              </div>
            </div>
          </div>

          <div className="demo__side demo__side_phone" ref={node => this.phoneSide = node}>
            <div className="demo__shadow-coin" ref={node => this.shadowCoin = node}>
              <span></span>
              <span></span>
            </div>
            <div className="demo__coin" ref={node => this.coin = node}>
              <img src="/img/coin.png" alt="Coin" />
            </div>
            <div className="demo__phone" ref={node => this.phone = node}>
              <div className="demo__circles">
                <Circles count={5} />
              </div>
              {this.createScreen(true)}
              {this.createScreen()}
              <div className="demo__dotts" ref={node => this.dotts = node}>
                <span className="demo__dott-item"></span>
                <span className="demo__dott-item"></span>
                <span className="demo__dott-item"></span>
                <span className="demo__dott-item"></span>
                <span className="demo__dott-item"></span>
              </div>
              <div className="demo__body" ref={node => this.phoneBody = node}>
                <img src="/img/demo/phone.png" alt="Phone" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};
