import React, { Component } from 'react';

import './Item.sass';

export default class Item extends Component {
  render() {
    const {
      title,
      text,
      icons
    } = this.props;

    return (
      <div className="item" ref={node => this.node = node}>
        <h5 className="item__title js-demo-title">{title}</h5>
        <p className="item__text">{text}</p>
        {
          icons
            ?
              (
                <div className="item__icons">
                  {
                    icons.map((src) => 
                      <span
                        className="item__icon"
                        style={{
                          backgroundImage: `url(${src})`
                        }}
                        key={src}
                      />
                    )
                  }
                </div>
              )
            : null
        }
      </div>
    );
  }
};
