import React, { Component } from 'react';
import classNames from 'classnames';

import './Button.sass';

export default class Button extends Component {
  getClassName() {
    const { color, width } = this.props;

    return classNames('btn', {
      'btn_pink': color === 'pink' || !color,
      'btn_green': color === 'green',
      'btn_blue': color === 'blue',
      'btn_violet': color === 'violet',
      'btn_full': width === 'full'
    });
  }

  render() {
    const { url, title } = this.props;
    const className = this.getClassName();

    return url 
      ? <a href={url} target="_blank" rel="noopener noreferrer" className={className}>
          <span className="btn__text">{title.toUpperCase()}</span>
        </a>
      : <button className={className}>{title.toUpperCase()}</button>
    ;
  }
};
