import React, { Component } from 'react';
import classNames from 'classnames';
import { ReactComponent as Arrow } from 'icons/arrow-right.svg';

import './Header.sass';

export default class Header extends Component {

  handleClick(e, index) {
    e.preventDefault();

    this.props.handleClick(index);
  }

  render() {
    const { title, modifier } = this.props;


    return (
      <div className={classNames("details-header", modifier ? modifier : '')}>
        <button
          className="details-header__back"
          onClick={() => this.props.handleClick(null)}>
          <Arrow/>
        </button>
        <div className="details-header__title">{title}</div>
      </div>
    )
  }
}
