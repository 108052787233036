import React, { Component } from 'react';
import Content from 'components/Details/Content/Content';
import Header from 'components/Details/Header/Header';
import Sidebar from 'components/Details/Sidebar/Sidebar';
import Button from 'components/Button/Button';

import './Details.sass';

export default class Details extends Component {

  render() {
    const {
      title,
      items,
      activeItem,
      sidebarTitle,
      handleClick,
      btn
    } = this.props;

    return (
      <div className="vacancies-details">
        <div className="vacancies-details__in">
          <div className="vacancies-details__body">
            <Header
              handleClick={handleClick}
              modifier="is-column"
              title={title}/>
            <Content activeItem={activeItem}/>

            <div className="vacancies-details__btn">
              <Button
                color="pink"
                title={btn.title}
                url={btn.url}
              />
            </div>
          </div>

          <div className="vacancies-details__sidebar">
            <Sidebar
              sidebarTitle={sidebarTitle}
              items={items}
              activeItem={activeItem}
              handleClick={handleClick}
            />
          </div>
        </div>
      </div>
    )
  }
}
