import React, { Component } from 'react';
import classNames from 'classnames';

import './Socials.sass';

export default class Socials extends Component {
  render() {
    return (
      <ul className="socials">
        {
          this.props.links.map((item) => (
            <li key={item.url} className={classNames("socials__item", { [`socials__item_${item.label}`]: item.label })}>
              <a
                href={item.url}
                aria-label={item.label}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  className="socials__icon"
                  style={{
                    backgroundImage: `url(${item.icon.url})`
                  }}
                ></span>
              </a>
            </li>
          ))
        }
      </ul>
    );
  }
};
