import React, { Component } from 'react';

import './Partners.sass';

export default class Partners extends Component {
	render() {
		const {
			title,
			items
		} = this.props;

		const itemsList = items.map(item => item.item);

		return (
			<div className="partners">
				<h3 className="partners__headline" data-aos="fade-up">{title}</h3>
				<div className="partners__items">
					{itemsList.map(item => (
						<div className="partners__item-wrapper" key={item.url} data-aos="fade-up">
							<div className="partners__item">
								<img src={item.url} alt={item.alt}/>
							</div>
						</div>
					))}
				</div>
			</div>
		)
	}
}
