import React, { Component, createRef } from 'react';
import Slick from 'react-slick';
import classNames from 'classnames';
import { ReactComponent as Arrow } from 'icons/angle-right-light.svg';

import 'slick-carousel/slick/slick.css';
import './Slider.sass';

export default class Slider extends Component {
  constructor() {
    super();

    this.slderRef = React.createRef();

    this.state = {
      settings: {
        arrows: false,
        centerMode: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        onSwipe: this.onSwipe.bind(this),
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    };

    this.prevBtn = createRef();
    this.nextBtn = createRef();
  }

  onSwipe() {
    if (this.slider) {
      this.slider.innerSlider.clickable = true;
    }
  }

  slickToNext() {
    this.slider.slickNext();
  }

  slickToPrev() {
    this.slider.slickPrev();
  }

  addListeners() {
    this.prevBtn.current.addEventListener('click', this.slickToPrev.bind(this));
    this.nextBtn.current.addEventListener('click', this.slickToNext.bind(this));
  }

  componentDidMount() {
    this.addListeners();
  }

  render() {
    return (
      <div className={classNames('slider', {'slider_news': this.props.newsSlider})} data-aos={this.props.newsSlider ? "fade-up" : null} ref={this.slderRef}>
        <Slick {...this.state.settings} ref={s => (this.slider = s)}>
          {
            this.props.slides.map((item) => (
              <a href={item.url} className="slider__item" target="_blank" rel="noopener noreferrer" key={item.text}>
                <div className="slider__content">
                  {
                    item.image
                    ? <div className="slider__image">
                        <img src={item.image.url} alt={item.image.alt}/>
                      </div>
                    : ''
                  }
                  <p className="slider__text">"{item.text}"</p>
                  {item.logo ? <img
                    src={item.logo.url}
                    alt={item.alt}
                  /> : ''}
                  {this.props.moreBtn
                    ? <div className="slider__more">
                        {this.props.moreBtn}
                        <Arrow/>
                        </div>
                    : ''}
                </div>
              </a>
            ))
          }
        </Slick>
        <div className="slider__btn slider__btn_prev" ref={this.prevBtn}></div>
        <div className="slider__btn slider__btn_next" ref={this.nextBtn}></div>
      </div>
    );
  }
};
