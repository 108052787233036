import React, { Component, createRef } from 'react';
import classNames from 'classnames';

import { ReactComponent as Arrow } from 'icons/arrow-right.svg';

import './Subscribe.sass';

const url = 'https://api.ethereum-express.com/subscribe/';

export default class Subscribe extends Component {
  constructor() {
    super();

    this.state = {
      isValid: true,
      isSended: false
    };

    this.classes = {
      error: 'is-error',
      success: 'is-success'
    };

    this.form = createRef();
    this.inputs = [];

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.inputs = [...this.form.current.querySelectorAll('[name]')];
  }

  checkEmail(value) {
    // eslint-disable-next-line
    const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(value);
  }

  isValid(item) {
    const type = item.type;

    switch(type) {
      case 'email':
        return this.checkEmail(item.value);
      default:
        return true;
    }
  }

  resetState() {
    this.setState({
      isValid: true,
      isSended: false
    });
  }

  resetForm() {
    this.form.current.reset();
    window.setTimeout(this.resetState.bind(this), 2000);
  }

  send(data) {
    fetch(
      url,
      {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )
      .then((resp) => {
        console.log(resp);
        this.setState({
          isValid: true,
          isSended: true
        });
        this.resetForm();
      })
      .catch(error => console.log(error))
    ;
  }

  handleSubmit(e) {
    e.preventDefault();

    const isValid = this.inputs.every(item => this.isValid(item));

    if (isValid) {
      const data = this.inputs.reduce((res, item) => {
        res[item.getAttribute('name')] = item.value;
        return res;
      }, {});

      this.send(data);
    } else {
      this.setState({ isValid: false });
    }

    return false;
  }

  render() {
    const { isValid, isSended } = this.state;
    const { placeholder, success, error } = this.props;

    return (
      <form
        className={classNames('subscribe', {
          'is-error': !isValid,
          'is-success': isSended && isValid
        })}
        onSubmit={this.handleSubmit}
        ref={this.form}
        noValidate
      >
        <div className="subscribe__field">
        <div className="subscribe__input">
          <input type="email" placeholder={placeholder || null} name="email" aria-label="email" />
          {
            <div className="subscribe__msg">
              {!isValid ? error : null}
              {isValid && isSended ? success : null}
            </div>
          }
        </div>
          <button
            type="submit"
            className="subscribe__submit"
            title="Submit"
          >
            <Arrow />
          </button>
        </div>
      </form>
    );
  }
};
