import React, { Component } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { getPageDataUrl } from 'Utils';

import { default as AboutSection } from 'sections/About/About';
import JoinUs from 'sections/JoinUs/JoinUs';

import './About.sass';

const pageDataUrl = getPageDataUrl('about');
const elementsDataUrl = getPageDataUrl('elements');

export default class About extends Component {
  constructor() {
    super();

    this.state = {
      pageData: null,
      elementsData: null,
    };
  }

  componentDidMount() {
    this.setData('pageData', pageDataUrl);
    this.setData('elementsData', elementsDataUrl);
		AOS.init();
  }

  async setData(stateKey, url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Something goes wrong');
      }
      const myJson = await response.json();
      this.setState({
        [stateKey]: myJson[0].acf
      }, () => {
				AOS.refresh();
			});
    } catch (error) {
      console.log(error.message);
    }
  }

  render() {
    return (
      this.state.pageData &&
      this.state.elementsData
      ? <div className="about">
          <div className="about__in">
            <AboutSection
              text={this.state.pageData.intro}
              image={this.state.pageData.image}
              items={this.state.pageData.items}
              story={this.state.pageData.story}
							coin={this.state.pageData.coin}
							partners={this.state.pageData.partners}
							news={this.state.pageData.news}
            />
          </div>

          <JoinUs
            allScreensShown={true}
            headline={this.state.elementsData.join.headline}
            subscribe={this.state.elementsData.join.subscribe}
            partner={this.state.elementsData.join.partner}
            comunity={this.state.elementsData.join.comunity}
          />
        </div>
      : ''
    )
  }
};
